import React, { useContext, useEffect, useState} from 'react'
import arrow from "../../../assets/images/icons/arrow.png"
import { Link, useNavigate } from 'react-router-dom'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_OUR_FUNCTIONS } from '../../../constants/urls'
import { useTranslation } from 'react-i18next'

const TempusActivity = () => {
  const navigation = useNavigate()
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_OUR_FUNCTIONS)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  const { t } = useTranslation();

  return (
    <div className='tempus'>
      <h2>{t("funk1")}<span> {t("funk2")} </span> {t("funk3")}. </h2>
      <div className="tempus__cards">
        {data?.length ? data?.map((item, index) => (
          <div
              className="tempus__card"
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => navigation(`/${lang}/moretempus`, { state: item?.detail_func })}>
            <div className="tempus__card__info">{item?.name}</div>
            <div className="tempus__card__img"><img src={item?.image} alt="" /></div>
            <div className="tempus__card__link" style={{ fontSize: 20 }}>Batafsil<img src={arrow} alt="arrow"/></div>
          </div>
        )) : ''}
      </div>
    </div>
  )
}

export default TempusActivity
