import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import {Link, useParams} from 'react-router-dom';
import axios from 'axios';
import { API_PATH } from '../../../constants/tools';


const Axborot5 = ({ changeLang }) => {

    const [news, setNews] = useState([]);
    const T = useTranslation();
    const {lang} = useParams()
    const getNews = async () => {
        await axios
            .get(API_PATH + `/${T.i18n.language === 'kr' ? 'ky' : T.i18n.language}/functions/video-gallery/`)
            .then((res) => {
                setNews(res.data.reverse());
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const { t } = useTranslation();
    const changeLanguage = (e) => {
        changeLang(e.target.value);
    };

    useEffect(() => {
        getNews();
    }, [T.i18n.language]);




    return (
        <>

            <div className="Axborot1"    style={{paddingTop: 140}}>
                <div className="containerr">
                    <div className="row">
                        <div className="col-12">
                            <div className="axb_nam">
                                {t('rahbar_14')}
                            </div>
                        </div>
                        {news && news.map((item, index) => (
                            <Link to={`/${lang}/in-videogalareya/${item.id}`} key={index} className="col-lg-4 mt-5 ">
                                <div className="foto_box">
                                    <video muted className='foto_vid' src={item.video} alt="" />
                                    {/* <video muted className='foto_vid' src='/video/rolik_AOK_fx_fx_tg_fx.mp4' alt="" /> */}
                                    <div className="foto_text">
                                        <div className="foto_h">
                                            {item.text}
                                        </div>
                                        <div className="foto_down">
                                            <div className="foto_info">
                                                <div className="foto_main">
                                                    <img src="/img/icon_eye_1.png" alt="" />
                                                    {item.views}
                                                </div>
                                                <div className="foto_main">
                                                    <img src="/img/icon_eye_2.png" alt="" />
                                                    {item.created_at.slice(0, 10)}
                                                </div>
                                            </div>
                                            <Link className='foto_l' to={`/${lang}/in-videogalareya/${item.id}`}>{t('more')}</Link>

                                        </div>
                                    </div>

                                </div>

                            </Link>
                        ))}



                    </div>
                </div>
            </div>
        </>
    )
}

export default Axborot5
