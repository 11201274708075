import React, { useContext, useEffect, useState} from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_AIM } from '../../../constants/urls'

const AboutCommitee = () => {
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_AIM)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    if (lang) getData()
  }, [lang])

  return (
    <section className='AboutCommite'   style={{paddingTop: 140}}>
      <div className="containerr">
        <h2 className='commite-title'>{data[0]?.name}</h2>
        <img className='comite-img' src={data[0]?.image_main} alt="" />

        {data[0]?.detail_goal?.length ? data[0]?.detail_goal?.map((item, index) => (
          <div data-aos="fade-right" className="commite-info1" key={index}>
            <div className='commite-info1__info'>
              <p data-aos="fade-right"  dangerouslySetInnerHTML={{ __html: item?.text }}/>
            </div>
            <img data-aos="fade-left" src={item?.image} alt="" />
        </div>
        )) : ''}
      </div>
    </section>
  )
}

export default AboutCommitee
