import React, { useContext, useEffect, useState} from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_HISTORY } from '../../../constants/urls'
export default function Markaztarixi() {
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async() => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_HISTORY)}`)
    if(info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <section className='Markaztarixi'   style={{paddingTop: 140}}>
      {data?.length ? data?.map((item, index) => (
        <div className="containerr" key={index}>
          <img className='markaztarixi-img' src={item?.image} alt="" />
          <h2>{item?.title}</h2>
          <div className='markaz-tarixi__info'>
            <p dangerouslySetInnerHTML={{ __html: item?.content }}/>
          </div>

          <div className="row justify-content-center">
            <div className="col-6">
            <img className='markaztarixi-img w-100' src={item?.image_2} alt="" />
            </div>
          </div>
        </div>
      )) : ''}
    </section>
  )
}
