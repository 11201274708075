import React from "react";
import img from "../../../assets/images/icons/commiteinfoimg1.png";
import Consectetur from "./Consectetur";
import read1 from "../../../assets/images/icons/read-more1.jpg";
import read2 from "../../../assets/images/icons/read-more2.jpg";
import read3 from "../../../assets/images/icons/read-more3.jpg";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Jamoatchilik() {
  const [id, setId] = useState({});
  const {id: paramId} = useParams()
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const T = useTranslation();

  const getById = async () => {
    await axios
      .get(API_PATH + `${T.i18n.language}/functions/public-hearing/${paramId}/`)
      .then((res) => {
        setId(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getById();
  }, [path, T.i18n.language]);

  return (
    <section className="ReadMoreNews"    style={{paddingTop: 140}}>
      <div className="containerr">
        <img className="raedmorenews-img1" src={id.image} alt={id.title} />
        <div className="readmorenews-info1">
          <h2>{id.title}</h2>
          {/* <p>Neque, proin enim, posuere lectus eleifend gravida vitae. Purus odio sit arcu et, quis risus dui quis massa. Feugiat mollis arcu arcu viverra amet in. </p> */}
        </div>

        <div className="raedmorenews-info2">
          {/* <h3>dsfsdfsd</h3> */}
          <p className="" dangerouslySetInnerHTML={{ __html: id?.text }} />

          <div className=" row">
            {id.aboutcenter &&
              id.aboutcenter.map((item2, index2) => (
                <div key={index2} className="col-12 mb-4">
                  <img className="detail_img" key={index2} src={item2.image} alt={item2.text} />
                  <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: item2?.text }} />
                </div>
              ))}

          </div>

        </div>

        {/* <Consectetur /> */}
      </div>
    </section>
  );
}
