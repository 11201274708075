import React from "react";
import img from "../../../assets/images/icons/commiteinfoimg1.png";
import Consectetur from "./Consectetur";
import read1 from "../../../assets/images/icons/read-more1.jpg";
import read2 from "../../../assets/images/icons/read-more2.jpg";
import read3 from "../../../assets/images/icons/read-more3.jpg";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function Videogalareya() {
    const [id, setId] = useState({});
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const {id: paramId} = useParams()
    const T = useTranslation();

    const getById = async () => {
        await axios
            .get(API_PATH + `${T.i18n.language}/functions/video-gallery/${paramId}/`)
            .then((res) => {
                setId(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getById();
    }, [path, T.i18n.language]);

    return (
        <section className="ReadMoreNews"    style={{paddingTop: 140}}>
            <div className="containerr">

                <video src={id.video} className='raedmorenews-img1' muted controls  >
                    <source type="video/mp4" />
                </video>
                <div className="readmorenews-info1">
                    <div className="foto_info">
                        <div className="foto_main_2">
                            <img src="/img/icon_eye_1_1.png" alt="" />
                            {id.views}
                        </div>
                        <div className="foto_main_2">
                            <img src="/img/icon_eye_1_2.png" alt="" />
                            {id.created_at}
                        </div>
                    </div>
                    <h2>{id.title}</h2>
                </div>
                <div className="raedmorenews-info2">
                    <p className="" dangerouslySetInnerHTML={{ __html: id?.text }} />
                    <div className=" row">
                        {id.new_detail &&
                            id.new_detail.map((item2, index2) => (
                                <div key={index2} className="col-12 mb-4">
                                    <img className="detail_img" key={index2} src={item2.image} alt={item2.text} />
                                    <div className="foto_info">
                                        <div className="foto_main_2">
                                            <img src="/img/icon_eye_1_1.png" alt="" />
                                            {id.views}
                                        </div>
                                        <div className="foto_main_2">
                                            <img src="/img/icon_eye_1_2.png" alt="" />
                                            {id.created_at}
                                        </div>
                                    </div>
                                    <h3 className="mt-5" dangerouslySetInnerHTML={{ __html: item2?.text }} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </section>
    );
}
