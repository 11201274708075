const translationsEn = {
  home: "HOME",
  about: "ABOUT",
  activity: "ACTIVITY",
  services: "OPEN DATA",
  services2: "Services",
  contact: "CONTACT",
  herotittle: "STATE ECOLOGICAL EXPERTISE CENTER",
  staff: "STAFF",
  followUs: "Follow us",
  number: "Number",
  address: "Address",
  email: "Email",
  latestnews: "Latest news",
  news: "News",
  documents: "Legislative documents",
  hotlines: "Trust phone",
  services_info: "List of interactive services available through the portal",
  see_all: "See all",
  more: "More",
  send1: "Send",
  xizmat1: "Conducting a state environmental review of draft state programs, concepts and schemes for the development of the social sphere, as well as management plans for specially protected natural areas",
  xizmat2: "Passing the state ecological examination of projects of production forces, concepts and development of economic sectors",
  xizmat3: "Conducting the state ecological examination of materials for the selection of land plots for all types of construction",
  xizmat4: "State environmental examination of pre-project and project documents by types of activities",
  xizmat5: "Passing state environmental expertise of drafts of normative-technical and instructional-methodical documents regulating economic and other activities related to the use of natural resources",
  xizmat6: "Passing the state environmental examination of documents related to the creation of new types of products, technology, materials, substances",
  xizmat7: "Carrying out state ecological examination of operating enterprises and other objects that have a negative impact on the natural environment and the health of citizens",
  xizmat8: "In order to give the territories the status of protected natural territories, emergency ecological situation and ecological disaster zones, the state ecological examination of their comprehensive inspection materials",
  xizmat9: "Passing urban planning documents through the state environmental expertise",
  connect_span: "Connect",
  connect: "with us",
  naming: "FULL NAME",
  pgone: "Phone number",
  maill: "E-mail",
  texx: "Message content",
  ukaz: "Legislative documents",
  region_tittle: 'INFORMATION about employees of the Department of Ecology and Environmental Protection of the Republic of Uzbekistan "Ecological Expertise Center" of the Republic of Karakalpakstan, regions and Tashkent city department',
  user_data: "Time and place of birth",
  fio: "Full name",
  user_tell: "Phone number",
  appointment: "Position",
  education: "Education and expertise",
  language: "en",
  list: "More",
  location: "Tashkent city, Mirzo-Ulugbek district, Sayram 5-tor street 15",
  funk1: "Functions",
  funk2: "and",
  funk3: "tasks",
  rahbar4_4: "Central Apparat",

  ariza: "Analysis of applications and appeals",

  inter1: "Send an order",

  faunk4:"Calculator",
  inter:"Interactive services",
  kabin: "Log In",

  rahbar1_1: "Center Branches",
  rahbar1: "About the employees of the Center for State Ecological Expertise",
  rahbar2: "FULL NAME",
  rahbar3: "Reception is carried out at the following address",
  rahbar4: "Telephone number",
  rahbar5: "Job title",
  rahbar6: "E-mail",
  rahbar8: "Reception times",

  rahbar_14: `Videogallery`,
  text_1:"Loading information...",


  nav_19_1: " International relations",
  rahbar_2: `Conclusions`,
  rahbar_3: `Projector rating`,
  rahbar_3_11: "Placing an order",
  rahbar_4: `Information Service`,
  rahbar_5: `Public hearing`,

  nav_1_2: `Organizational Structure`,

  nav_3_2: `Regional Branches`,
  nav_4_2: `Legislative documents`,
  nav_5_2: `Open data`,

  // korrupsion

  kor1: "THE CENTER FOR STATE ENVIRONMENTAL EXPERTISE OF THE STATE COMMITTEE FOR ECOLOGY AND ENVIRONMENTAL PROTECTION OF THE REPUBLIC OF UZBEKISTAN THE MAIN DIRECTIONS IN THE FIGHT AGAINST CORRUPTION",
  kor2: "Introduction",
  kor3: "Factors that determine corruption and the reasons for its occurrence",
  kor4: "The main directions of state policy on the fight against corruption",
  kor5: "Development of the Anti-Corruption System of the Republic of Uzbekistan",
  kor6: "Corruption",
  kor7: "Corruption (Latin: corruptio — nausea, sale of bribes) is the unlawful use of a person's personal interests from his career or service position in order to obtain material or intangible benefits in the interests of other persons, as well as the unlawful provision of such a Naf, the acquisition of officials, their sale of bribes; the sale of elected, appointed positions;",
  kor8: "Lobbism (a system of institutions that deal with the harassment of monopolies to legislative bodies and officials). At the international level, an example of the largest manifestations of corruption is the Lockside case of the purchase of high government officials by the company in the sale of aircraft to Japan in the 1970s. For the payment of bribes, the practice of paying more service fees is used in large agreements.",
  kor9: "Manifestations of conflict of interest",
  kor10: "Lobbizm (ingliz. lobbi-kuluar, koridor)",
  kor11: "a specific type of political influence in formal decision-making by meeting informal individuals among themselves",
  kor12: "Rahnamolik, favoritizm (fr. favorit)",
  kor13: "close retrieval and maintenance of some personnel under khimoya",
  kor14: "Protectionism (lot. assist)",
  kor15: "assistance in the appointment of certain persons to the position",
  kor16: "Nepotizm (lat. nepos jiyan)",
  kor17: "to work with narrow group interests in state structures or other structures on the basis of ethnic principles",
  kor18: "Mijozlik (ingliz tili. clientelism, lat. mijozela-meyoz)",
  kor19: "official in the position and persons sympathetic to IT (officials, entrepreneurs, political groups)",
  kor20: "Cronism (English. humphicist, familiar knowledge, grouping)",
  kor21: "submission of positions on a familiar-cognitive basis",
  kor22: "Factors that determine corruption and the reasons for its occurrence",
  kor23: "Corruption offense-is understood to be an act that has signs of corruption, for the commission of which the legislation provides for liability",
  kor24: "The term corruption comes from the Latin word corruptio, which means violation, derailment of the system, deviation in exchange for bribes",
  kor25: "Corruption is understood to be the unlawful use of a person's own career or service position in order to obtain a material or intangible benefit in the interests of his personal interests or other persons, as well as the unlawful provision of such a NAF",
  kor26: "History of the fight against corruption",
  kor27: "Arastu",
  kor28: "In any state system, the most important thing is to organize work by means of laws and procedures in such a way that officials can make a fortune by Qing'ir way",
  kor29: "Sitseron",
  kor30: "He came to the rich province as a poor, and from the poor province became rich",
  kor31: "Edmund Berk",
  kor32: "In a society where corruption strikes, freedom cannot reign for a long time",
  kor33: "Piter Aygen",
  kor34: "Corruption is the abuse of state power on the way to personal hatred",
  kor35: "There are several factors of the origin of corruption crimes",
  kor36: "Anthropological factors",
  kor37: "The contradictory nature of Man, the constant struggle of goodness and evil, the desire of man to get more profit with less effort.",
  kor38: "Social factors",
  kor39: "Social stratification of society, the presence of “risk groups”, violation of labor legislation, non-observance of the principles of social justice in the use of labor of migrant workers, women and adolescents.",
  kor40: "Legal factors",
  kor41: "The legal basis of entrepreneurship is imperfection, the presence of a conflict between the changing conditions of the market economy and the existing legislative base, the lack of regulation of the household.",
  kor42: "Ethical factors",
  kor43: "conflicts between legislation and the moral foundations of entrepreneurship, conflicts between the state and the interests of society, the state and the individual; lack of accounting of national traditions, the influence of religious rules, etc.",
  kor44: "Anthropological factors",
  kor45: "High levels of transactional costs of entrepreneurial activity (“law-abiding assessment”), competition within the framework of market economy, uneven development of various sectors of the economy, etc.",
  kor46: "Political factors",
  kor47: "the relationship between power and large capital is the ratio between the formation of the oligarchy, the power of power and power-based methods of influencing economic activity.",
  kor48: "Household corruption",
  kor49: "It is common mainly in the relationship of officials and civil servants with citizens in their daily work activities",
  kor50: "There are several types of corruption",
  kor51: "Business corruption",
  kor52: "It is common mainly in the relationship of officials and civil servants with citizens in their daily work activities",
  kor53: "Corruption of higher authorities",
  kor54: "It is common mainly in the relationship of officials and civil servants with citizens in their daily work activities",
  kor55: "The factors that create the conditions for the commission of corruption crimes are insufficient organizational and managerial and social control over this disease.",
  kor56: "They include",
  kor57: "Deficiencies in planning, violation of the requirements of the contract by the participants, defects in the organization of service activities (distribution of obligations, establishment of authority, excess of working capital, etc.).",
  kor58: "Failure and excessive spending (the use of energy resources, material and human resources without savings), defects in educational work.",
  kor59: "Lack of control over the benefits and expenses of officials, employees in the activities of law enforcement and supervisory authorities and the performance of service duties by them, as well as neglect of corruption cases.",
  kor60: "Deficiencies in the selection and placement of personnel (recruitment of uninitiated and inexperienced or unreliable persons on the basis of relative seed production, familiarity and cognition, and similar circumstances).",
  kor61: "Slow calculation and control.",
  kor62: "Causes of the emergence of corruption cases",
  kor63: "Lower monthly salaries in the public sector than in the private sector",
  kor64: "The country's economy is largely controlled by the state",
  kor65: "The establishment of single rule in the state in certain types of services",
  kor66: "Severance of relations of state governing bodies with the people",
  kor67: "Non-existence of economic stability",
  kor68: "The nation's lack of ethnic solidarity",
  kor69: "Low rates of economic development (per capita GDP growth)",
  kor70: "The presence of radical-religious views in society",
  kor71: "Low rates of economic development (per capita GDP growth)",
  kor72: "Low legal culture of society",
  kor73: "The existence of the norms of law, which are interpreted in two ways",
  kor74: "Political instability in the country",
  kor75: "Slow public control of citizens over the activities of state bodies",
  kor76: "The high level of political patronage, skillfulness, Kindred and fellow-brotherly relations in society and public administration",
  kor77: "One of our priorities will be the fight against corruption and organized crime, as well as the effective solution of crime prevention issues",
  kor78: "President Of The Republic Of Uzbekistan Shavkat Mirziyoyev",
  kor79: "The Republic of Uzbekistan carries out international cooperation in the field of anti-corruption with a number of international organizations",
  kor80: "EGMONT GROUP (Financial Intelligence Association)",
  kor81: "UNODC (UN Office of drugs and crime)",
  kor82: "Eudp (UN Development Programme)",
  kor83: "OSCE (Organization of security and cooperation in Europe)",
  kor84: "OECD (Organization of Economic Cooperation and development)",
  kor85: "FATF (Organization against illegal income legalization)",
  kor86: "Priorities of state policy on the fight against corruption in the Republic of Uzbekistan",
  kor87: "Priorities of state policy on the fight against corruption in the Republic of Uzbekistan",
  kor88: "Removal of legal restrictions and organizational barriers for the development of entrepreneurship",
  kor89: "Improving the legal and institutional framework for the provision of public services and the regulation of administrative procedures",
  kor90: "Implementation of administrative reforms, improvement of the functions of state bodies, transfer of non-state functions to the private sector",
  kor91: "Improving the goals, objectives and functions of judicial and law enforcement bodies in the field of anti-corruption",
  kor92: "Establishing a communication system between government bodies, public and civil society institutions",
  kor93: "Improving the system of training, retraining and professional development of personnel, the formation of high moral standards",
  kor94: "Improving the goals, objectives and functions of judicial and law enforcement bodies in the field of anti-corruption",
  kor95: "In order to prevent the commission of corruption crimes and offenses, the Prevention of offenses, ensuring the dissemination and use of Legal Information, The Fight Against Corruption, The Supreme Council of judges, state procurement, protection of victims, witnesses and other participants in criminal proceedings, and a number of other cones were adopted",
  kor96: "Development of the Anti-Corruption System in the Republic of Uzbekistan:",
  kor97: "Timely identification of corruption offenses, putting an end to them, eliminating their consequences, the reasons and conditions that allow them, ensuring the principle of inevitability of responsibility for committing corruption offenses.",
  kor98: "Raising the legal consciousness and legal culture of the population, the formation of an uncompromising attitude towards corruption in society",
  kor99: "Implementation of measures to prevent corruption in all spheres of state and public life;",
  kor100: "The system of bodies and institutions that directly carry out the Prevention of corruption offenses",
  kor101: "Internal affairs bodies",
  kor102: "State Security Service bodies",
  kor103: "Bodies of the state Customs Service",
  kor104: "Prosecution authorities",
  kor105: "Justice authorities",
  kor106: "Measures aimed at reducing profits from corrupt activities can include",
  kor107: "Introduction of a fair payment of labor for civil servants and other system of Social Interest",
  kor108: "Encourage honesty",
  kor109: "Establishing the obligation of dismissal for violation of anti-corruption rules",
  kor110: "Abolition of career immunity",
  kor111: "Allow fair and the same conditions for all to grow in terms of career and position",
  kor112: "Creating suitable conditions for retirement, etc. k",
  kor113: "Information about corruption violations and signs of corruption in relation to employees of the center and its territorial branches, to the control of the center's anti-corruption and complayens– (93) 593 48 89; (71) 203 00 22” (1019) or directly to the Personnel Department 93 386 20 10; “(71) 203 00 22” (1010) it is desirable that it be provided by landline and fast mobile phones",
  ish: "Work Time",
  rahbar1_7: "Persons responsible for posting public information",
  nav_1: `Goals and directions`,
  nav_2: `Basics of legal activity`,
  nav_3: `Central appart and structural division`,
  nav_4: `Center History`,
  nav_5: `Leadership`,
  nav_6: `News`,
  nav_7: `Youth policy`,
  nav_8: `Activity Indicators`,
  nav_9: `International Relations`,
  nav_10: `Fight against corruption`,
  nav_11: `Function and tasks`,
  nav_12: `Youth policy`,
  nav_13: `Legislation`,
  dav1: "State institution",
  dav2: "Working day: ",
  dav3: "Monday – Friday, 9:00 -18:00",
  dav4: "Day off:",
  dav5: "Saturday, Sunday",
  dav6: "Tel: ",
  dav7: "Exat",
  dav8: "E-mail",
  fot1: "Communication",
  nav_14: `Government Services`,
  nav_15: `Open data`,
  nav_16: `Frequently Asked Questions and Answers`,
  nav_17: `Services`,
  nav_18: `Open data on finance`,
  nav_19: `Vacancies`,
  nav_20: `Persons responsible for posting public information`,
  nav_21: `Information Request Review`,
  nav_22: `Contact Information Service`,
  nav_23: `Register of State Environmental Expertise Conclusions`,
  nav_24: `Approved semi-annual and annual work plans of state bodies and organizations`,
  nav_25: `Reports on the activity of the center`,
  nav_26: `Information on the results of consideration of citizens' appeals and appeal channels`,

  nav_info_1: "Mail",
  nav_info_2: "Telegram channel",
  nav_info_3: "Support team",
  nav_info_4: "INSTRUCTION on sending an order for state environmental expertise",
  nav0: "Press conferencion",
  calculator: `Calculator`,

  vacancy_company: "Company",
  vacancy_location: "Location",

  nav_test: `The site is in test mode`,

  kr1: "Fight against corruption",
  kr2: "January",
  kr3: "Code of ethics of employees of the State Environmental Expertise Center",
  kr4: "Regulation on the anti-corruption policy at the State Environmental Expertise Center",
  kr5: "January",
  kr6: "On the organization of rapid investigation of appeals received from legal entities and individuals through social networks and mass media",
  kr7: "A short biography",
  kr8: "Tasks",
  kr10: "LABOR ACTIVITY",
  kr9: "Mukhamedov Gayrat Aminovich was born on February 19, 1966 in Bukhara region. Education - higher, in 1992, Tashkent State Agrarian University, Forestry Engineer, in 2004, Master of State and Society Construction, Academy of State and Society Construction under the President of the Republic of Uzbekistan, in 2005, Bukhara State University, Faculty of Economics. finished his studies.",
  kr11: "1983-2001. - Started in various positions in agriculture and forestry.",
  kr12: "2002-2011 - in the position of the director of the Ohangaron forestry enterprise of the Ugam-Chotkal state nature park, Tashkent region",
  kr13: "2001-2002 - Head of the Bukhara Region Forestry Department",
  kr14: "2011-2015 - Chairman of the Nature Protection Committee of Tashkent region",
  kr15: "2015-2016 - The State Committee for Nature Protection of the Republic of Uzbekistan in the position of the head of the Republican State Inspection for the Protection and Rational Use of Animal and Plant World",
  kr16: "2016-2021 - in the position of the executive director for Uzbekistan, general director of the British company PETROMARUZ OVERSEAS",
  kr17: "2021-2021 - in the position of general director of the foreign enterprise SURHANCEMENTINVEST",
  kr18: "2021-2022 - in the position of deputy chairman of the Cotton-textile clusters of Uzbekistan association",
  kr19: "2022 - h.v. - He is working as the General Director of the state institution State Environmental Expertise Center of the Ministry of Ecology, Environmental Protection and Climate Change of the Republic of Uzbekistan.",
  kr20: "Aytmuratov Timurlan Parakhatovich was born on April 5, 1991 in the Chimboy district of the Republic of Karakalpakstan. Education - higher, in 2013 he graduated from the Tashkent Institute of Irrigation and Agricultural Mechanization Engineers in the field of ecology and environmental protection, in 2015 he graduated from the Tashkent Institute of Chemical Technology (master's degree) in the field of chemical technology of inorganic substances.",
  kr21: "2013-2017 - He started as a leader, chief specialist of the state unitary enterprise State Ecological Certification and Standardization Center under the State Committee for Ecology and Environmental Protection of the Republic of Uzbekistan.",
  kr22: "2017-2021 - in the position of director of the state unitary enterprise State Ecological Certification and Standardization Center under the State Committee for Ecology and Environmental Protection of the Republic of Uzbekistan.",
  kr23: "2021-2022 - Director of the state unitary enterprise Tashkent City State Ecological Expertise Center under the State Committee for Ecology and Environmental Protection of the Republic of Uzbekistan",
  kr24: "2022 - h.v. - He is working as the first deputy director general of the state institution State Ecological Expertise Center of the Ministry of Ecology, Environmental Protection and Climate Change of the Republic of Uzbekistan.",
  kr25: "Shi-syan Vyacheslav Viktorovich was born on February 22, 1982 in the Tashkent region. Higher education - master's degree, graduated from the Tashkent Institute of Chemical Technology in 2006 with a degree in environmental protection.",
  kr26: "2003-2006 - He started as an inspector of the nature conservation committee of the Tashkent region.",
  kr27: "2006-2007 - as head of the nature conservation committee of the Tashkent region.",
  kr28: "2007-2008 - as deputy general director of the limited liability company Nature Construction Project.",
  kr29: "2007-2008 - v dolzhnosti Generalnogo directora Obshchestva s ogranichennoy otvetstvennostyu Ekoligiat-Norm.",
  kr30: "2012-2013 - as a senior specialist at BOSHDAVEKOEXPERTIZA.",
  kr31: "2013-2014 - as deputy head of BOSHDAVEKOEXPERTIZA.",
  kr32: "2014-2022. - as General Director of the limited liability company ECO PROM CONSULTING.",
  kr33: "2022 - h.v. - He works in the position of the Deputy Director General of the State Institution Center of Environmental Expertise of the Ministry of Ecology, Environmental Protection and Climate Change of the Republic of Uzbekistan.",

  kr34: "Questions",
  kr35: "Did you like our website?",
  kr36: "Very good",
  kr37: "Good",
  kr38: "Satisfactory",
  kr39: "Voting",
  kr40: "Answers",

  rahbar_10: `Press release`,
  rahbar_11: `Press conference`,
  rahbar_12: `Infographic`,
  rahbar_13: `Photogallery`,
  hamk: "Partners",
  kar1: "Tashkent region branch of the State Environmental Expertise Center",
  kar2: "Select area",
  kar3: "Tashkent",
  kar4: "Tashkent Region",
  kar5: "Andijan Region",
  kar6: "Fergana Region",
  kar7: "Namangan Region",
  kar8: "Sirdarya Region",
  kar9: "Jizzakh Region",
  kar10: "Samarkand Region",
  kar11: "Kashkadarya Region",
  kar12: "Surkhandarya Region",
  kar13: "Navoi Region",
  kar14: "Khorezm Region",
  kar15: "Republic of Karakalpakstan",

  applications: "Applications",
  received: "Received",
  reviewed: "Reviewed",
  on_process: "In Process",
  reports: "Reports",
  application_title: "Applications and Reports Analysis"


};

export default translationsEn;
