import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import pdf1 from '../../../assets/files/Call center.pdf'
import Pdf from '../../../assets/images/icons/pdf-img.svg'
import { GlobalContext } from '../../GlobalContext';
import { API_PATH } from '../../../constants/tools';
import axios from 'axios';
import { GET_INFOGRAFIKA } from '../../../constants/urls';




const Axborot3 = () => {
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_INFOGRAFIKA)}`)
        if (info?.data?.length) {
            setData(info.data)
            setData2(info.data)
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])


    const { t } = useTranslation();



    return (
        <>

            <div className="Axborot1"    style={{paddingTop: 140}}>
                <div className="containerr">
                    {data && data.map((item, index) => (
                        <div key={index} className="row">
                            <div className="col-12">
                                <div className="axb_nam">
                                    {item.title}
                                </div>
                            </div>
                            {item && item?.images?.map((item2, index) => (
                            <div className="col-lg-4 mt-5 mb-5">
                                <img className='w-100 h-100' src={item2?.image} alt="" />
                            </div>
                              ))}
                        </div>
                    ))}

                </div>
            </div>

        </>
    )
}

export default Axborot3
