import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import pdf1 from '../../../assets/files/Call center.pdf'
import Pdf from '../../../assets/images/icons/pdf-img.svg'
import { GlobalContext } from '../../GlobalContext';
import { API_PATH } from '../../../constants/tools';
import axios from 'axios';
import { GET_PRESS } from '../../../constants/urls';




const Axborot1 = () => {

    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_PRESS)}`)
        if (info?.data?.length) {
            setData(info.data)
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])

    const { t } = useTranslation();



    return (
        <>

            <div className="Axborot1"    style={{paddingTop: 140}}>
                <div className="containerr">
                    <div className="row">
                        <div className="col-12">
                            <div className="axb_nam">
                                {t('rahbar_10')}
                            </div>
                        </div>

                        {data && data.map((item, index) => (
                            <div key={index} className="col-12">
                                <div className='decrees-info'>
                                    <div className='decrees-data'>
                                        <h3>{item.day}</h3>
                                        <h4>{item.month}</h4>
                                        <p>{item.year}</p>
                                    </div>

                                    <div className="decrees-title">
                                        <p>{item.title}</p>
                                        <div >
                                            <a target={'_blank'} rel="noreferrer" href={item.file}><img src={Pdf} alt="" /></a>
                                            {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a> */}
                                        </div>
                                    </div>
                                </div>


                            </div>
                        ))}

                    </div>
                </div>
            </div>

        </>
    )
}

export default Axborot1
