import React from 'react'
import { Link } from 'react-router-dom'

const Work = () => {
    return (
        <>

            <div className="HObject Work"    style={{paddingTop: 140}}>
                <div className="containerr">
                    <div className="row">
                        <div className="col-12">
                            <div className="h_news_name_box">
                                <div className="h_news_name">
                                    Bizning xizmatlar
                                </div>
                                <Link className='h_news_l' to=''>{`Barchasini ko'rish`}</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="h_news_box">
                                <div className="h_news_img">
                                    <img src="/img/h_news_1.jpg" alt="" />
                                </div>
                                <div className="h_news_name">
                                    Qonunchilik palatasi majlislari
                                </div>
                                <div className="h_news_h">
                                    Qishloq xo‘jaligi kooperativlarini tashkil etish asoslari belgilanmoqda
                                </div>
                                <div className="h_news_p">
                                    Oliy Majlis Qonunchilik palatasining navbatdagi majlisida “Qishloq xo‘jaligi kooperativi to‘g‘risida”gi qonun loyihasi birinchi o‘qishda konseptual jihatdan ko‘rib chiqildi. Keyingi yillarda mamlakatimizda agrar sohada olib borilgan islohotlar natijasida 260 ming gektar yer maydonlarida yangi dehqon xo‘jaliklari tashkil etildi. Ular tomonidan yetishtirilgan …
                                </div>
                                <div className="h_news_date">
                                    10.08.2024
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Work
