import React, { useState, useContext, useEffect} from 'react'
import axios from 'axios';
import './korrupsion.css';
import korrupsionimg from '../../../assets/images/icons/Group 482.png'
import korrupsionimg2 from '../../../assets/images/icons/Rectangle 153.png'
import korrupsionimg3 from '../../../assets/images/icons/image 13.png'
import Group497 from '../../../assets/images/icons/Group 497.png'
import Group498 from '../../../assets/images/icons/Group 498.png'
import Group499 from '../../../assets/images/icons/Group 499.png'
import image25 from '../../../assets/images/icons/image 25.png'
import image27 from '../../../assets/images/icons/image 27.png'
import image29 from '../../../assets/images/icons/tmmBywo5kKY_waifu2x_photo_noise2 3.png'
import image30 from '../../../assets/images/icons/Rectangle 193.png'
import image31 from '../../../assets/images/icons/image 29.png'
import { useTranslation } from 'react-i18next';
import { API_PATH } from '../../../constants/tools';
import { GlobalContext } from '../../GlobalContext';
import { GET_CORRUPTION } from '../../../constants/urls';



export default function Korrupsion({changeLang}) {
    const { t } = useTranslation();
    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async() => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_CORRUPTION)}`)
        if(info?.data?.length) {
        setData(info.data)
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])

    return (
        <>
            <div className="korrupsion"   style={{paddingTop: 140}}>
                {data?.length ? data.map((item, index) => (
                    <div className="container" key={index}>
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <h1>{item?.title}</h1>
                        </div>
                        <div className="col-lg-12 mx-auto">
                            <img src={item?.image} alt="" />
                        </div>
                        <div className="col-lg-12 mx-auto">
                            <p className='vxod'>{t('kor2')}</p>
                        </div>
                        <div className="col-lg-12  mx-auto mt-3">
                            <div className="tabling">
                                <div className="row">
                                    {item?.cor_intro?.length ? item?.cor_intro.map((intro, introIndex) => (
                                        <div className={introIndex === 0 || introIndex % 2 !== 0 ? "col-lg-4 mb-4 second_korrupsion" : "col-lg-4 mb-4"}>
                                            <p className='p_table_corrupsion'>{intro?.text}</p>
                                        </div>
                                    )): ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            {item?.cor_detail?.length ? item.cor_detail.map((detail, detailIndex) => (
                                <div className="row" key={detailIndex}>
                                    <div className="col-lg-6">
                                        <p className='vxod vxod_2'>{t('kor6')}</p>
                                        <p className='vxod-2' style={{ textAlign: 'justify' }} dangerouslySetInnerHTML={{ __html: detail?.text}} />
                                    </div>
                                    <div className="col-lg-6 korupsionimage">
                                        <img src={detail?.image} alt="" />
                                    </div>
                                </div>
                            )): ''}
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className='vxod'>{t('kor9')}</p>
                                <div className="col-lg-9 for_lobbizm">
                                    <div className="row">
                                        {item?.cor_conflict?.length ? item.cor_conflict.map((conflict, conflictIndex) => (
                                            <div className="col-lg-6" key={conflictIndex}>
                                            <p className='text_about_korupsion'>
                                                <b>{conflict?.text}</b>
                                            </p>
                                        </div>
                                        )) : ''}
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <img className='korrupsionimg3' src={korrupsionimg3} alt="" />
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod" style={{ textAlign: 'center' }}>
                                   {t('kor22')}
                                </p>
                                <div className="col-lg-12 mx-auto omillar_korrupsion">
                                    {item?.cor_factor?.length ? item.cor_factor.map((factor, factorIndex) => (
                                        <div className="row" key={factorIndex}>
                                            <hr className='frtsvds' />
                                            <div className="col-lg-3"><p className='number_korrupsion'>0{factorIndex + 1}</p></div>
                                            <div className="col-lg-6"><p className='korrupsion_num'>{factor?.text}</p></div>
                                            <div className="col-lg-3"><div className="img_korrupsion_three">
                                                <img src={factor?.image} alt="" />
                                            </div>
                                            </div>
                                        </div>
                                    )) : ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod">{t('kor26')}</p>
                                <div className="col-lg-5"><img src={image25} alt="" /></div>
                                <div className="col-lg-6 right_papka">
                                    {item?.cor_history?.length ? item?.cor_history?.map((history, historyIndex) => (
                                        <div className="row" key={historyIndex}>
                                            <hr className='frst_line' />
                                            <div className="col-lg-5">
                                                <p className='number_korrupsion'>{history?.name}</p>
                                            </div>
                                            <div className="col-lg-7">
                                                <p className='korrupsion_num'>{history?.text}</p>
                                            </div>
                                        </div>
                                    )): ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod" style={{ textAlign: 'center' }}>{t("kor35")}</p>
                                <div className="col-lg-12 for_four_card">
                                    <div className="row for_grid">
                                        {item?.cor_crime?.length ? item?.cor_crime?.map((crime, crimeIndex) => (
                                                <div className="col-lg-3 classname_card" key={crimeIndex}>
                                                    <p className='xuquq_text'>{crime?.name}</p>
                                                    <p className='asosi_xuquq'>{crime?.text}</p>
                                                </div>
                                        )): ''}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <p className="vxod22">{t("kor55")}</p>
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-4">
                                            <p className='xuquq_text'>{t("kor56")}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className='asosi_xuquq'>{t("kor57")}</p>
                                            <br />        <p className='asosi_xuquq'>{t("kor58")}</p>
                                            <br />     <p className='asosi_xuquq'>{t("kor59")}</p>
                                        </div>
                                        <div className="col-lg-4">
                                            <p className='asosi_xuquq'>{t("kor60")}</p>
                                            <p className='asosi_xuquq xuquqning'>{t("kor61")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <p className="vxod22">
                                    {t("kor62")}
                                </p>
                                <div className="col-lg-10 mx-auto for_landing_etxt">
                                    {item?.cor_cases?.length ? item?.cor_cases?.map((cases, casesIndex) => (
                                         <div className="row ">
                                             {casesIndex % 2 !== 0 ? <div className="col-lg-6"></div> : ''}
                                            <div className="col-lg-6">
                                                <p className={casesIndex % 2 === 0 ? 'left_frstvsds' : 'right_frstvsds'}>{cases?.text}</p>
                                            </div>
                                            {casesIndex % 2 === 0 ? <div className="col-lg-6"></div> : ''}
                                        </div>
                                    )) : ''}
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod33">
                                {t("kor77")}
                                </p>
                                <div className="col-lg-12">
                                    <img src={image27} alt="" />
                                </div>
                                <div className="col-lg-12 mx-right">
                                    <p className='text_right_president'>{t("kor78")}</p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className='vxod'>{t("kor79")}</p>
                                <hr className='frst_lineee' />
                                <div className="col-lg-12 mx-auto">
                                    <div className="row for_justify">
                                        <div className="col-lg-6">
                                            <p className='texting_for_la'>{t("kor80")}</p>
                                            <hr />
                                            <p className='texting_for_la'>{t("kor81")}</p>
                                            <hr />
                                            <p className='texting_for_la'>{t("kor82")}</p>
                                            <hr />
                                            <p className='texting_for_la'>{t("kor83")}</p>
                                            <hr />
                                            <p className='texting_for_la'>{t("kor84")}</p>
                                            <hr />
                                            <p className='texting_for_la'>{t("kor85")}</p>
                                            <hr />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod">
                                {t("kor86")}
                                </p>
                                <div className="col-lg-12 mt-4">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <p className='text_oo'>01/</p>
                                            <hr />
                                            <p className='korrupsion_num'>{t("kor87")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p className='text_oo'>02/</p>
                                            <hr />
                                            <p className='korrupsion_num'>{t("kor88")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p className="text_oo">03/</p>
                                            <hr />
                                            <p className='korrupsion_num'>{t("kor89")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p className="text_oo">04/</p>
                                            <hr />
                                            <p className='korrupsion_num'>{t("kor90")}</p>
                                        </div>
                                    </div>
                                    <div className="row mt-5">
                                        <div className="col-lg-3">
                                            <p className='text_oo'>05/</p>
                                            <hr />
                                            <p className="korrupsion_num">{t("kor91")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p className="text_oo">06/</p>
                                            <hr />
                                            <p className="korrupsion_num">{t("kor92")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p className="text_oo">07/</p>
                                            <hr />
                                            <p className="korrupsion_num">{t("kor93")}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                        <p className="vxod22">{t("kor94")}</p>
                            <div className="row for_justify">
                                <div className="col-lg-6">
                                    <img src={image29} alt="" />
                                    <p className='korrupsion_after'>{t("kor95")}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto">
                            <div className="row">
                                <p className="vxod">{t("kor96")}</p>
                                <div className="col-lg-6">
                                    <img src={image30} className='image_rectinge' alt="" />
                                </div>
                                <div className="col-lg-6">
                                    <ul className='ulling'>
                                        <li>{t("kor97")}</li>
                                        <li>{t("kor98")}</li>
                                        <li>{t("kor99")}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mx-auto">
                            <hr />
                            <div className="row">
                                <div className="col-lg-12 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <p className='vxod33 topdan-olsh'>{t("kor100")}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <ul className='ulling'>
                                                <li>{t("kor101")}</li>
                                                <li>{t("kor102")}</li>
                                                <li>{t("kor103")}</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3">
                                            <ul className='ulling'>
                                                <li>{t("kor104")}</li>
                                                <li>{t("kor105")}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="col-lg-12 mx-auto">
                               <div className="row">
                               <div className="col-lg-6">
                                    <p className='vxod33 topdan-olsh'>{t("kor106")}</p>
                                </div>
                                <div className="col-lg-6">
                                    <ul className='ulling'>
                                        <li>{t("kor107")}</li>
                                        <li>{t("kor108")}</li>
                                        <li>{t("kor109")}</li>
                                        <li>{t("kor110")}</li>
                                        <li>{t("kor111")}</li>
                                        <li>{t("kor112")}</li>
                                    </ul>
                                </div>
                                <hr />
                               </div>
                            </div>
                        </div>


                        <div className="col-lg-12 mx-auto mb-5">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p className='markazuchun'>{t("kor113")}</p>
                                </div>
                                <div className="col-lg-6">
                                    <img src={image31} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                )): ''}
            </div>

        </>
    )
}
