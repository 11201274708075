import React from "react";
import img from "../../../assets/images/icons/commiteinfoimg1.png";
import Consectetur from "./Consectetur";
import read1 from "../../../assets/images/icons/read-more1.jpg";
import read2 from "../../../assets/images/icons/read-more2.jpg";
import read3 from "../../../assets/images/icons/read-more3.jpg";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function ReadMoreNews() {
    const [id, setId] = useState({});
    const location = useLocation();
    const path = location.pathname.split("/")[2];
    const T = useTranslation();

    const getById = async () => {
        await axios
            .get(API_PATH + `/${T.i18n.language}/aboutcenter/elonlar/${path}/`)
            .then((res) => {
                setId(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getById();
    }, [path, T.i18n.language]);

    return (
        <section className="ReadMoreNews"   style={{paddingTop: 140}}>
            <div className="containerr">
                <img className="raedmorenews-img1" src={id.image} alt={id.title} />
                <div className="readmorenews-info1">
                    <h2>{id.name}</h2>
                    <p className="" dangerouslySetInnerHTML={{ __html: id?.content }} />
                </div>
            </div>
        </section>
    );
}
