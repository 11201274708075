import React, { useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { GET_NEWS } from '../../../constants/urls'
import './news.css'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function NewsPage() {
    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)

    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_NEWS)}`)
        if (info?.data?.length) {
            setData(info.data.reverse())
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])

    const { t } = useTranslation();

    return (

        <div className="news"   style={{paddingTop: 140}}>
            <div className="news__info">
                <h2>{t("news")}</h2>
            </div>
            <div className="news__cards ">

                {data &&
                    data.map((item, index) => (
                        <div key={index} className="row mb-5" data-aos="fade-right">
                            <div className=" col-lg-5 mb-lg-0 mb-5">
                                <Link to={`/${lang}/readmorenews/${item.id}`} className="news__card__img">
                                    <img className="news-images" src={item.image} alt={item.title} />
                                </Link>
                            </div>
                            <div className=" col-lg-7 ">
                                <div className="news__card__info">
                                    <div className="news__card__info__text">
                                        <h3>
                                            {item.title}
                                        </h3>
                                    </div>
                                    <div className="news__card__info__btn">
                                        <p dangerouslySetInnerHTML={{ __html: item?.content?.slice(0, 1000) }} />
                                        <Link to={`/${lang}/readmorenews/${item.id}`} className="read-more-link">
                                            {t("more")}
                                        </Link>
                                        <div className="foto_info">
                                            <div className="foto_main_2">
                                                <img src="/img/icon_eye_1_1.png" alt="" />
                                                {item.views}
                                            </div>
                                            <div className="foto_main_2">
                                                <img src="/img/icon_eye_1_2.png" alt="" />
                                                {item.created_at.slice(0, 10)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

            </div>
        </div>

    )
}
