import React, { useContext, useEffect, useState } from 'react'
import Decrees from './Decrees'
import { useTranslation } from 'react-i18next';
import Pdf from '../../../assets/images/icons/pdf-img.svg'
import pdf1 from '../../../assets/files/Call center.pdf'
import pdf2 from '../../../assets/files/jamoatchilik shartnoma 11 son.pdf'
import pdf3 from '../../../assets/files/jamoatchilik shartnoma5.pdf'
import axios from 'axios';
import { GlobalContext } from '../../GlobalContext';
import { API_PATH } from '../../../constants/tools';
import { GET_COR } from '../../../constants/urls';



  const Korruption = () => {

  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
      const info = await axios(`${API_PATH.concat(lang).concat(GET_COR)}`)
      if (info?.data?.length) {
          setData(info.data)
      }
  }

  useEffect(() => {
      getData()
  }, [lang])
  const { t } = useTranslation();




  return (
    <section className='Documents'    style={{paddingTop: 140}}>




      <section className='Decrees' data-aos="fade-right">
        <div className='containerr'>
          <div className="korruption">
            <h2>{t("kr1")}</h2>
          </div>


          {data && data.map((item, index) => (
         <div  key={index}>
           <div className='decrees-info'>
            <div className='decrees-data'>
              <h3>{item.day}</h3>
              <h4>{item.month}</h4>
              <p>{item.year}</p>
            </div>

            <div className="decrees-title">
              <p>{item.title}</p>
              <div >
                {/* <a target={'_blank'} rel="noreferrer" href={pdf1}><img src={Pdf} alt="" /></a> */}
                {/* <a target={'_blank'} rel="noreferrer" href={item.link}><img src={Doc} alt="" /></a>
                */}
                  <a target={'_blank'} rel="noreferrer" href={item.file}><img src={Pdf} alt="" /></a>
              </div>
            </div>
          </div>
         </div>

))}

        </div>
      </section>

    </section>
  )
}



export default Korruption;
