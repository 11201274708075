import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { GlobalContext } from '../GlobalContext'
import { API_PATH } from '../../constants/tools'
import axios from 'axios'
import { GET_CITIZIENS } from '../../constants/urls'
import arrow from "../../assets/images/icons/arrow.png"

const Citiziens = () => {
  const navigation = useNavigate()
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_CITIZIENS)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])

  return (
    <div className='tempus'    style={{paddingTop: 140}}>
      <h2>Fuqorolarning murojatlarini ko'rib chiqish natijalari<br /><span> va  </span> murojaat kanallari bo'yicha ma'lumot.</h2>
      <div className="tempus__cards">
        {data?.length ? data?.map((item, index) => (
          <div
            className="tempus__card"
            key={index}
            style={{ cursor: 'pointer' }}
            onClick={() => navigation(`/${lang}/moretempus`, {
              state: [
                {
                  title: item?.title,
                  content: item?.content,
                  image: item?.image,
                  sub_title: item?.sub_title,
                  sub_image: item?.sub_image,
                  sub_content: item?.sub_content
                }
              ]
            })}>
            <div className="tempus__card__info">{item?.title}</div>
            <div>
              <div className="tempus__card__img"><img src={item?.image} alt="" /></div>
              <div className="tempus__card__link" style={{ fontSize: 20 }}>Batafsil<img src={arrow} alt="arrow" /></div>
            </div>
          </div>
        )) : ''}
      </div>
    </div>
  )
}

export default Citiziens
