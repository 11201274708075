import React from 'react'
import { useContext } from 'react'
import { useState } from 'react'
import { GlobalContext } from '../../GlobalContext'
import { GET_ELONLAR, GET_NEWS } from '../../../constants/urls'
import { API_PATH } from '../../../constants/tools'
import axios from 'axios'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import pdf from '../../../assets/images/icons/pdf-img.svg'

const Elonlar = () => {

    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)

    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_ELONLAR)}`)
        if (info?.data?.length) {
            setData(info.data.reverse())
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])

    const { t } = useTranslation();


    return (
        <>
            <div className="news"    style={{paddingTop: 140}}>
                <div className="news__info">
                    <h2>{t("nav_6")}</h2>
                </div>
                <div className="news__cards ">

                    {data &&
                        data.map((item, index) => (
                            <div key={index} className="row mb-5" data-aos="fade-right">
                                <div className=" col-lg-5 mb-lg-0 mb-5">
                                    <Link to={`/${lang}/elon/${item.id}`} className="news__card__img">
                                        <img className="news-images" src={item.image} alt={item.title} />
                                    </Link>
                                </div>
                                <div className=" col-lg-7 ">
                                    <div className="news__card__info">
                                        <div className="news__card__info__text">
                                            <h3>
                                                {item.name}
                                            </h3>
                                            <a target={"blank"} href={item.file} className="filega">
                                                <img src={pdf} alt="" />
                                            </a>
                                        </div>
                                        <div className="news__card__info__btn">
                                            <p dangerouslySetInnerHTML={{ __html: item?.content?.slice(0, 1000) }} />
                                            <Link to={`/${lang}/elon/${item.id}`} className="read-more-link">
                                                {t("more")}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}

                </div>
            </div>
        </>
    )
}

export default Elonlar
