import React, { useEffect } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { useState } from 'react'
// import Pagoda from '../../../assets/images/icons/pagoda.svg'
// import Opros from '../../../assets/images/icons/opros.svg'
import Form from 'react-bootstrap/Form';
import video_eco from '../../../assets/xw4u5mo16f2skvel328x.mp4'
import { useTranslation } from 'react-i18next'
import ariza1 from '../../../assets/ariza2.png'
import ariza2 from '../../../assets/ariza1.png'
import ariza3 from '../../../assets/absentee_11179556 1.png'
import ariza4 from '../../../assets/document_16503746 1.png'
import ariza5 from '../../../assets/file_1336072 1.png'


export default function Kalendar() {
  const [value, onChange] = useState(new Date());

  const { t } = useTranslation();

  return (
    <>
      <div className='video_home'>
        <div className="container">
          <div className="col-12">
            <div className="part_name_box">
              <div className="part_h_2">
                {t('nav_info_4')}
              </div>
            </div>
          </div>
          <div className="col-12">
            <video className='video_home_page' controls>
              <source src={video_eco} type="video/mp4"/>
            </video>
          </div>

          <h4 style={{fontSize: 40, height: 100}}>{t('application_title')}</h4>

        <div className="ariza1">
        <div className={'col-lg-12 d-flex justify-content-between align-items-center'}>
           <div className={'text-center w-25 d-flex flex-column align-items-center'}
                 style={{fontSize: 20, height: 100}}>
                  <img src={ariza1} alt="" />
             <p>{t('applications')}</p>
            </div>

            <div className={'text-center w-25  d-flex flex-column align-items-center gap-1'}
                 style={{fontSize: 20, height: 100}}>
                  <img src={ariza3} alt="" />
             {t('received')}  <br/> <br/> 
              <button className='btn_ariza1'>85 824</button>
            </div>
            <div className={'text-center w-25  d-flex flex-column align-items-center gap-1'}
                 style={{fontSize: 20, height: 100}}>
                  <img src={ariza4} alt="" />
             {t('reviewed')}  <br/> <br/> 
              <button className='btn_ariza1'>85 366</button>
            </div>

            <div className={'text-center w-25  d-flex flex-column align-items-center gap-1'}
                 style={{fontSize: 20, height: 100}}>
                  <img src={ariza5} alt="" />
             {t('on_process')}  <br/> <br/> 
              <button className='btn_ariza1'>458</button>
            </div>
                  
          </div>
        </div>


          <div className={'col-lg-12 d-flex justify-content-between align-items-center mt-3'}>
            <div className={'text-center w-25 d-flex flex-column align-items-center justify-content-center'}
                 style={{fontSize: 20, height: 100}}>
                    <img src={ariza2} alt="" />
              <p>{t('reports')}</p>
            </div>
            <div className={'text-center w-25  d-flex align-items-center justify-content-center'}
                 style={{fontSize: 20, height: 100}}>
               <button className='btn_ariza1'>692</button>
            </div>
            <div className={'text-center w-25  d-flex align-items-center justify-content-center'}
                 style={{fontSize: 20, height: 100}}>
            
             <button className='btn_ariza1'>685</button>
            
            </div>
            <div className={'text-center w-25  d-flex align-items-center justify-content-center'}
                 style={{fontSize: 20, height: 100}}>
               <button className='btn_ariza1'>7</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
