import React, {useContext} from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useEffect } from "react";
import { useState } from "react";
import {GlobalContext} from "../../GlobalContext";

const Axborot2 = ({ changeLang }) => {
  const [news, setNews] = useState([]);
  const { lang } = useContext(GlobalContext)
  const T = useTranslation();
  const getNews = async () => {
    await axios
      .get(API_PATH + `/${T.i18n.language === 'kr' ? 'ky' : T.i18n.language}/functions/press-conference/`)
      .then((res) => {
        setNews(res.data.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const { t } = useTranslation();
  const changeLanguage = (e) => {
    changeLang(e.target.value);
  };

  useEffect(() => {
    getNews();
  }, [T.i18n.language]);

  return (
    <div className="news"    style={{paddingTop: 140}}>
      <div className="news__info">
        <h2>{t("nav0")}</h2>
        <span>

        </span>
      </div>
      <div className="news__cards">

        {news &&
          news.map((item, index) => (
            <div key={index} className="news__card" data-aos="fade-right">
              <Link to={`/${lang}/markaz-anjumani/${item.id}`} className="news__card__img">
                <img className="news-images" src={item.image} alt={item.title} />
              </Link>
              <div className="news__card__info">
                <div className="news__card__info__text">
                  <h3>
                    {item.title}
                  </h3>
                  {/* <span>{item.created_at.slice(0, 10)}</span> */}
                </div>
                <div className="news__card__info__btn">
                  <p dangerouslySetInnerHTML={{ __html: item?.text?.slice(0, 400) }} />
                  <Link to={`/${lang}/markaz-anjumani/${item.id}`} className="read-more-link">
                    {t("more")}
                  </Link>
                  <div className="foto_info">
                    <div className="foto_main_2">
                      <img src="/img/icon_eye_1_1.png" alt="" />
                      {item.views}
                    </div>
                    <div className="foto_main_2">
                      <img src="/img/icon_eye_1_2.png" alt="" />
                      {item.created_at.slice(0, 10)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

      </div>
    </div>
  );
};

export default Axborot2;
