import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { API_PATH } from '../constants/tools';
import { GlobalContext } from './GlobalContext';
import { GET_FOOT } from '../constants/urls';

const Foot = ({ changeLang }) => {
    const { t } = useTranslation();
    const changeLanguage = (e) => {
        changeLang(e.target.value)
    }

    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)

    const getData = async () => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_FOOT)}`)
        if (info?.data?.length) {
            setData(info.data.reverse())
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])



    return (
        <>

            <div className="Foot">

                {/* <img className='foot_img' src="/img/foot_img.png" alt="" /> */}

                <div className="containerr">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="for_footer_f">



                                <div className="foot_logo">
                                    <div className='d-flex align-items-center gap-3'>
                                        <img src="/img/foot_logo.png" alt="" />
                                        <div className="foot_logo_text">
                                            <div className="foot_logo_h">{t("herotittle")}</div>
                                            <div className="foot_logo_p">{t("dav1")}</div>
                                        </div>
                                    </div>

                                    <div className='mt-2 line-info d-lg-flex d-none align_foot'>
                                        <div className="foot_main_box">
                                        <img src="/img/icon_foot_2.png" alt="" />
                                         <div>
                                         <p>{t('nav_info_2')}</p>
                                            <li>
                                                <a href="https://t.me/eco_service_support" target="_blank" >{t('nav_info_3')}</a>
                                            </li>
                                         </div>
                                        </div>
                                    </div>
                                </div>


                                {data && data.map((item, index) => (
                                    <div key={index} className="api_text_foot">
                                        <div className="foot_main_name">
                                            {t("vacancy_location")}
                                        </div>
                                        <div className="foot_main_box">
                                            <img src="/img/icon_foot_main_1.png" alt="" />
                                            <div className="foot_main_text">
                                                <div className="foot_main_h">{item.address}</div>
                                            </div>
                                        </div>
                                        <div className="foot_main_name">
                                            {t("ish")}
                                        </div>
                                        <div className="foot_main_box">
                                            <img src="/img/icon_foot_main_2.png" alt="" />
                                            <div className="foot_main_text">
                                                <div className="foot_main_h">{t("dav2")}<span>{item.working_days}</span></div>
                                                <div className="foot_main_h">{t("dav4")}<span>{item.days_off}</span> </div>
                                            </div>
                                        </div>

                                     


                                    </div>
                                ))}


{data && data.map((item, index) => (
                               <div key={index} className="trher_foot">
                                   <div className="foot_main_name">
                                            {t("fot1")}
                                        </div>
                               <div className="foot_main_box">
                                    <img src="/img/icon_foot_main_3.png" alt="" />
                                    <div className="foot_main_text">
                                        <div className="foot_main_h">{t("dav6")}<span>{item.telephone}</span></div>
                                        <div className="foot_main_h">{t("dav7")}: <span>{item.fax}</span> </div>
                                        <div className="foot_main_h"> {t("dav8")}: <span>{item.email}</span></div>
                                    </div>
                                </div>
                                <div className="foot_soc">
                                    <a href="https://www.facebook.com/ecoekspertiza/" target="_blank" className="foot_soc_a">
                                        <img src="/img/icon_foot_1.png" alt="" />
                                    </a>
                                    <a href="https://t.me/ecoekspertiza" target="_blank" className="foot_soc_a">
                                        <img src="/img/icon_foot_2.png" alt="" />
                                    </a>
                                    <a href="https://youtube.com/channel/UCk1-8z1uI0fWDQRniifg6xw" target="_blank" className="foot_soc_a">
                                        <img src="/img/icon_foot_3.png" alt="" />
                                    </a>
                                </div>
                               </div>

                            ))}

                            </div>
                        </div>                

                        <div className="col-12">
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default Foot;
