import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_PATH } from "../../../constants/tools";
import axios from "axios";
import { GlobalContext } from "../../GlobalContext";
import './home.css'
import { GET_VACANCY } from "../../../constants/urls";
import VacancyComponent from "./VacancyComponent";

export default function Vacancy(){
    const [data, setData] = useState([])
    const [toggle, setToggle] = useState(false)
    const { lang } = useContext(GlobalContext)
    const { t } = useTranslation();

    const getData = async() => {
      const info = await axios(`${API_PATH.concat(lang).concat(GET_VACANCY)}`)
      if(info?.data?.length) {
        setData(info.data)
      }
    }

    useEffect(() => {
      getData()
    }, [lang])

    return(
      <>
      <div className="rahbariyat"    style={{paddingTop: 140}}>
      <div className="container">

        <p className="p_two">
                    {t("rahbar1_1")}
                  </p>
                  <div className="d-flex flex-column align-items-center container-fluid mb-1">
           {data?.length ? data?.map((item, index) => (
               <VacancyComponent item={item} index={index}/>
           )) : ''}

        </div>
      </div>
      </div>
        </>

    )
}
