import React from 'react'
import AccardionsStaff from './AccardionsStaff'

export default function Staff() {
  return (
    <section className='Staff'   style={{paddingTop: 140}}>
      <div className="containerr">
        <div>
          <h2>Ёйма рўйхат экспертиза Марказий аппарат</h2>
        </div>
        <AccardionsStaff/>
      </div>
    </section>
  )
}
