import React from "react";
import Consectetur from "./Consectetur";
import CarouselFadeExample from "./CarouselFadeExample";
import Kalendar from "./Kalendar";
import Partner from "./Partner";
import Contact from "../Contacts/Contact";

const Home = () => {
  return (
    <div   style={{paddingTop: 140}}>
      <CarouselFadeExample/>
      <Consectetur />
      <Kalendar/>
      <Partner/>
      <Contact/>
    </div>
  );
};

export default Home;
