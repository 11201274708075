import React from 'react'
// import Diu from '../Home/Diu'
import TempusActivity from './Tempusactivity'
import {useParams} from "react-router-dom";

const Activity = () => {
  const {lang} = useParams()

  return (
    <section className='Activity'   style={{paddingTop: 140}}>
      <div className="containerr">
        <TempusActivity/>
        {/* <Diu/> */}
      </div>
    </section>
  )
}

export default Activity
