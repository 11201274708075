import React from 'react'
import Contacts from './Contacts'
import Diu from '../Home/Diu'
import Karta from '../Home/Karta'

const Contact = () => {
  return (
    <div  style={{paddingTop: 140}}>
      <Contacts/>
      <Karta/>
    </div>
  )
}

export default Contact
