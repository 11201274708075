import React, { useEffect, useState, useContext } from "react";
import "./rahbariyat.css";
import axios from "axios";
import { API_PATH } from "../../../constants/tools";
import { useTranslation } from "react-i18next";
import { GET_LIST_DIRECTORS } from "../../../constants/urls";
import { GlobalContext } from "../../GlobalContext";

export default function Rahbar2({ changeLang }) {
  const { t } = useTranslation();
  const [data, setData] = useState([])
  const { lang } = useContext(GlobalContext)
  const getData = async () => {
    const info = await axios(`${API_PATH.concat(lang).concat(GET_LIST_DIRECTORS)}`)
    if (info?.data?.length) {
      setData(info.data)
    }
  }

  useEffect(() => {
    getData()
  }, [lang])


  return (
    <>
      <div className="rahbariyat"    style={{paddingTop: 140}}>
        <div className="container">
          <div className="col-lg-12 mx-auto">
            <div className="row">
              {/* <h1>Ёйма рўйхат экспертиза Марказий аппарат</h1> */}
              <hr />
              <div className="col-lg-12 mx-auto">
                <div className="row">
                </div>
                <div className="col-lg-12 mx-auto">
                  <div className="row">
                    <div className="for_rahbariyat">
                      {/* <p className="p_three">РАҲБАРИЯТ</p> */}
                      {data &&
                        data.slice(1,2).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-12 mx-auto">
                              <div className="rahbariya_1">
                                <div className="row">
                                  <div className="col-lg-4">
                                    <img className="rah_img" src={item?.image} alt="" />
                                  </div>

                                  <div className="col-lg-8">
                                    <p className="p_four">{t("rahbar2")} </p>
                                    <p className="p_five">
                                      {item?.name}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar3")}
                                    </p>
                                    <p className="p_five">
                                      {item?.address}
                                    </p>
                                    <p className="p_four">{t("rahbar4")}</p>
                                    <p className="p_five">{item?.phone}</p>
                                    <p className="p_four">
                                      {t("rahbar5")}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.title}
                                    </p>
                                    <p className="p_four">
                                      {t("rahbar6")}  {" "}
                                    </p>
                                    <p className="p_sixs">
                                      {item?.email}
                                    </p>
                                  </div>
                                  <div className="col-5">

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}

                            <div className="rahbar_1_text">
                                <p className="p_sixs">
                                  {t("kr20")}
                                </p>
                                <h2>
                                {t("kr10")}
                                </h2>
                                <p className="p_sixs" style={{marginBottom: "50px"}}>
                               {t("kr21")}
                               <br /> {t("kr22")}
<br />{t("kr23")}
<br />{t("kr24")}
                                </p>
                            </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  }
