import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../GlobalContext';
import { API_PATH } from '../../../constants/tools';
import axios from 'axios';
import { GET_TUZILMA } from '../../../constants/urls';

const Tuzilma = () => {
    const [data, setData] = useState([])
    const { lang } = useContext(GlobalContext)
    const getData = async() => {
        const info = await axios(`${API_PATH.concat(lang).concat(GET_TUZILMA)}`)
        if(info?.data?.length) {
        setData(info.data)
        }
    }

    useEffect(() => {
        if (lang) getData()
    }, [lang])
    const { t } = useTranslation();
    return (
        <>

            <div className="Tuzilma"    style={{paddingTop: 140}}>
                <div className="containerr">
                    <div className="row">
                        <div className="col-12">
                            <div className="h_news_name_box">
                                <div className="h_news_name">
                                   {t("nav_1_2")}
                                </div>
                            </div>

                        </div>
                        {data && data.map((item, index) => (
                        <div key={index} className="col-lg-12 mt-5 mb-5">
                            <img className='w-100' src={item.image} alt="IMAGE" />
                        </div>
                        ))}
                    </div>
                </div>
            </div>



        </>
    )
}

export default Tuzilma
