export const GET_OUR_FUNCTIONS = "/functions/funksiya-vazifalari/";

export const GET_ANSWERS_TO_QUESTIONS = "/functions/savol-javob/";

export const GET_AFFAIRS = "/functions/xizmatlar/";

export const GET_ARTICLE = "/functions/maqola/";

export const GET_FINANCE = "/opendata/moliyaviy-malumotlar";

export const GET_VACANCY = "/opendata/vacancy/";

export const GET_RESPONSIBLES = "/opendata/umumiy-ochiq/";

export const GET_INFORMATION = "/opendata/axborot-olish-malumotlar/";

export const GET_INFO_CENTER = "/opendata/malumotlar-markazi/";

export const GET_GOVERMENT = "/opendata/davlat-organlari/";

export const GET_CENTER_DATA = "/opendata/markaz-hisobot/";

export const GET_CITIZIENS = "/opendata/fuqarolar/";

export const GET_ACTIVITY_SHOW = "/aboutcenter/faoliyat-korsatkichlari/";

export const GET_CORRUPTION = "/aboutcenter/corruption/";

export const GET_APPARAT = "/news/markaz-tarkibi/";

export const GET_AIM = "/aboutcenter/maqsad/";

export const GET_LEGACY = "/aboutcenter/huquqiy/";

export const GET_NEWS = "/news/list-news/";

export const GET_YOUTH = "/aboutcenter/yoshlar-siyosati/";

export const GET_INTERNATIONAL = "/aboutcenter/xalqaro-aloqalar/";

export const GET_HISTORY = "/aboutcenter/markaz-tarxix/";

export const GET_LIST_WORKERS = "/news/list-worker/";

export const GET_LIST_DIRECTORS = "/news/list-director/";

export const GET_ELONLAR = "/aboutcenter/elonlar/";

export const GET_FOTOGALEREYA = "/functions/foto-gallery/"

export const GET_INFOGRAFIKA ="/functions/infografika/"

export const GET_MATBUOT = "/functions/press-conference/"

export const GET_PRESS = "/functions/press-reliz/"

export const GET_JAMOAT ="/functions/public-hearing/"

export const GET_FOOT = "/news/footer/"

export const GET_TUZILMA = "/functions/tashkiliy-tuzilma/"

export const GET_CORRUPTIONS = "/news/corruptions/"

export const GET_COR = "/news/corruptions/"